/*==========  Swiper Style  ==========*/

.swiper-entry {
  position: relative;
}

.swiper-container {
  z-index: auto;
}

.swiper-container:not(.swiper-no-swiping) .swiper-wrapper {
  //cursor: url(../../assets/img/drag.png) 16 9, ew-resize;
}

.swiper-container-vertical:not(.swiper-no-swiping) .swiper-wrapper {
  //cursor: url(../../assets/img/drag-vertical.png) 8 16, ns-resize;
}

.swiper-wrap{
  &.type-a{
    padding-right: 160px;
    display: block;
    overflow: hidden;
    .swiper-container{
      overflow: visible!important;
    }
    .swiper-slide{
      filter: blur(4px);
      transition: $transition;
      &.swiper-slide-active,
      &.swiper-slide-next{
        filter: blur(0);
      }
    }
  }
}

/*==========  Hover  ==========*/

//@media (min-width: 1200px){}

/*==========  MEDIA QUERIES  ==========*/

//@media (max-width: 1600px){}

//@media (max-width: 1368px){}

//@media (max-width: 1199px){}

// @media (max-width: 991px){}

// @media (max-width: 768px){}

// @media (max-width: 575px){}
