




































.block-layout{
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
}
#content-block.sp-no{
  padding: 0!important;
}
