
/*==========  BUTTONS  ==========*/

.btn {
	display: inline-block;
	vertical-align: bottom;
	text-align: center;
	position: relative;
	cursor: pointer;
	text-decoration: none;
	border: none;
	padding: 0;
	background: none;
	outline: none;
	font-size: 14px;
	line-height: 1.4em;
	font-weight: 500;
	&-wrapper{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		> .btn{
			margin-right: 8px;
			&:last-child{
				margin-right: 0;
			}
		}
		&.right{
			justify-content: flex-end;
		}
		&.sm{
			> .btn{
				margin-right: 4px;
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
}

.btn-primary {
	padding: 6px 12px;
	border-radius: 6px;
	background-color: $color1;
	color: #fff;
	transition: $transition;
	font-weight: 500;
	&:active {
		transform: scale(0.98);
	}
	&.is-active{
		background-color: #7161F3;
	}
	&.disable{
		background-color: $colorGrey2;
		color: $colorGrey3;
		pointer-events: none;
	}
	&.loader{
		color: rgba(1,1,1,0);
		pointer-events: none;
		position: relative;
		&:after{
			content: '';
			display: block;
			width: 16px;
			height: 16px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -8px;
			margin-top: -8px;
			background-image: url('../../assets/img/loader-image-2-white.svg');
			background-size: 100%;
			animation: spin 1s linear infinite;
		}
	}
	&.btn-loader{
		transition: all 0.14s ease-out;
	}
}

.btn-secondary {
	padding: 6px 12px;
	border-radius: 6px;
	background-color: $colorGrey1;
	color: $color3;
	transition: $transition;
	font-weight: 500;
	&.btn-icon{
		padding: 4px;
		line-height: 0;
	}
	.icon{
		display: inline-block;
		width: 23px;
		height: 23px;
		text-align: center;
		svg{
			max-width: 100%;
		}
		.fill{
			fill: #767676;
		}
		.stroke{
			stroke: #767676;
		}
		img{
			max-width: 100%;
			vertical-align: middle;
		}
	}
	&:active {
		transform: scale(0.98);
	}
	&.is-active{
		background-color: $color4;
		color: $color1;
		.icon{
			.fill{
				fill: #5E4BF1;
			}
			.stroke{
				stroke: #5E4BF1;
			}
		}
	}
	&.disable{
		background-color: $colorGrey2;
		color: $colorGrey3;
		pointer-events: none;
	}
}

.btn-outlined {
	padding: 6px 12px;
	border-radius: 6px;
	background-color: rgba(1,1,1,0);
	border: 1px solid $colorGrey5;
	color: $color1;
	transition: $transition;
	font-weight: 500;
	&:active {
		transform: scale(0.98);
	}
	&.is-active{
		background-color: $color5;
	}
	&.disable{
		border-color: $colorGrey2;
		color: $colorGrey3;
		pointer-events: none;
	}
	&.btn-icon{
		padding: 4px;
	}
	.icon{
		display: inline-block;
		width: 23px;
		height: 23px;
		text-align: center;
		img{
			max-width: 100%;
			vertical-align: middle;
		}
	}
	&.dark{
		color: $color3;
	}
	&.dark-gr{
		background-color: $colorGrey6-2;
		color: $color3;
		&.is-active{
			background-color: $color4;
			color: $color1;
		}
	}
}

.btn-ghost{
	padding: 6px 12px;
	border-radius: 6px;
	background-color: rgba(1,1,1,0);
	color: $color1;
	transition: $transition;
	//&:active {
	//	transform: scale(0.98);
	//}

	&.disable{
		color: $colorGrey3;
		pointer-events: none;
	}
	&.btn-icon{
		padding: 4px;
		line-height: 0;
	}
	.icon{
		display: inline-block;
		width: 23px;
		height: 23px;
		text-align: center;
		line-height: 0;
		img{
			max-width: 100%;
			vertical-align: middle;
		}
	}
	&.dark{
		color: #161616;
	}
	&.dark-gray{
		color: $colorGrey10;
	}
	&.is-active{
		background-color: $color4;
	}
	&.active{
		color: $color1;
		background-color: $color4;
	}
}

.btn-red{
	padding: 6px 12px;
	border-radius: 6px;
	background-color: #fa665b;
	color: #fff;
	transition: all .34s ease-out;
	font-weight: 500;
}

.btn-link{
	color: $color6;
	transition: $transition;
	cursor: pointer;
	.icon{
		display: inline-block;
		width: 16px;
		line-height: 0;
		margin-right: 4px;
		img{
			max-width: 100%;
		}
	}
	span{
		vertical-align: middle;
	}
	&.md{
		font-size: 16px;
	}
}
.btn-block {
	display: block;
	width: 100%;
}

.upload{
	&-die{
		border-radius: 8px;
		background-color: #E9E9E9;
		border: 1px solid #E6E6E6;
		padding: 8px 40px;
		position: relative;
		display: flex;
		align-items: center;
		display: inline-block;
		.size{
			background-color: $colorGrey6-2;
			color: #6F6F6F;
			padding: 2px 8px;
			border-radius: 16px;
			font-size: 14px;
			margin-left: 10px;
		}
		.file{
			color: #161616;
			font-size: 14px;
		}
		.icon{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 16px;
			line-height: 0;
			display: inline-block;
			width: 16px;
			img{
				width: 100%;
			}
		}
		.clean{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 16px;
			line-height: 0;
			display: inline-block;
			width: 13px;
			img{
				width: 100%;
			}
		}
		.bt-icon{
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			right: 16px;
			line-height: 0;
			display: inline-block;
			width: 16px;
			img{
				width: 100%;
			}
		}
		&.light{
			background-color: rgba(1,1,1,0);
			border: 1px solid $colorGrey5;
		}
		&.wd-max{
			width: 100%;
			max-width: 440px;
		}
	}
	&-wrapper{
		.upload-die{
			margin-top: 8px;
		}
	}
}

.btn-only-icon{
	padding: 4px;
	line-height: 0;
	background-color: rgba(1, 1, 1, 0);
	color: #767676;
	transition: $transition;
	&:active {
		transform: scale(0.96);
	}
	.icon{
		display: inline-block;
	}
}
.hv-svg-fill{
	fill: currentColor;
}

.btn-close{
	width: 32px;
	height: 32px;
	font-size: 16px;
	line-height: 32px;
	background-color: $colorGrey6-2;
	position: relative;
	z-index: 1;
	cursor: pointer;
	outline: none;
	display: block;
	text-align: center;
	color: #323232;
	border-radius: 50%;
	transition: $transition;
	&:active {
		transform: scale(0.98);
	}
	&:before {
		content: "\2716";
	}
}
.btn-outlined-circle{
	padding: 6px 12px;
	border-radius: 50%;
	background-color: #fff;
	border: 1px solid $colorGrey5;
	color: $color1;
	transition: $transition;
	font-weight: 500;
	padding: 14px;
	.icon{
		display: inline-block;
		width: 28px;
		height: 28px;
		text-align: center;
		img{
			width: 100%;
			height: 100%;
			vertical-align: middle;
		}
	}
	&.line-through{
		.icon{
			position: relative;
			&:after{
				content: "";
				display: block;
				width: 113%;
				height: 4px;
				background-color: #fff;
				border-bottom: 2px solid #767676;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) rotate(55deg);
				border-radius: 2px;
			}
		}
	}

}

.btn-group{
	display: inline-flex;
	background-color: $colorGrey8;
	background-color: $colorGrey8;
	border-radius: 8px;
	.btn-secondary:not(.is-active){
		background-color: $colorGrey8;
	}
	&.no-bg{
		background-color: rgba(1,1,1,0)
	}
}

.btn.btn-sort{
	font-size: 16px;
	color: $colorGrey7;
	display: flex;
	align-items: center;
	transition: $transition;
	span{
		display: inline-block;
	}
	.icon{
		line-height: 0;
		width: 8px;
		margin-left: 6px;
		position: relative;
		bottom: -1px;
		svg{
			max-width: 100%;
		}
	}
	.fill-grey{
		fill: $colorGrey7;
		transition: $transition;
	}
	&.md{
		font-size: 14px;
	}
	&.active{
		color: $color1;
		.fill-grey{
			fill: $color1;
		}
	}
}

/*==========  Hover  ==========*/

@media (min-width: 1200px){
	.btn-primary:hover:not(.is-active){
		background-color: #7161F3;
	}
	.btn-secondary:hover:not(.is-active){
		background-color: $colorGrey4;
	}
	.btn-outlined:hover:not(.is-active){
		background-color: $color5;
	}
	.btn-ghost:hover:not(.is-active){
		background-color: $color5;
	}
	.btn-red:hover:not(.is-active) {
		background-color: #ff877e;
	}
	.btn-link:hover{
		opacity: 0.9;
	}
	.btn-close:hover{
		background-color: $colorGrey4;
	}
	.btn-only-icon:hover{
		color: $color1;
	}
	.btn-outlined-circle:hover{
		background-color: #f2f1fe;
	}
	.btn.btn-sort:hover{
		color: $color1;
		.fill-grey{
			fill: $color1;
		}
	}
}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px)

// @media (max-width: 1199px)

// @media (max-width: 991px)

// @media (max-width: 768px)

// @media (max-width: 575px)
