
/*-------------------------------------------------------------------------------------------------------------------------------*/
/* GLOBAL SETTINGS */
/*-------------------------------------------------------------------------------------------------------------------------------*/

html {
	height: 100%;
}

body {
	height: 100%;
	overflow: hidden;
}

#content-block {
	position: relative;
	min-height: 100vh;
}

.overflow-hidden {
	overflow: hidden;
}

html.overflow-hidden body {
	overflow: hidden;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* ANIMATIONS */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.simple-animation {
	transform: translate3d(0, 30px, 0);
	transition: all 0.3s ease-out;
}

.animated .simple-animation, .simple-animation.animated {
	transform: none;
}

.delay-01 {
	transition-delay: .2s;
}

.delay-02 {
	transition-delay: .4s;
}

.delay-03 {
	transition-delay: .6s;
}

.delay-04 {
	transition-delay: .8s;
}

.delay-05 {
	transition-delay: 1s;
}

.delay-06 {
	transition-delay: 1.2s;
}

.delay-07 {
	transition-delay: 1.4s;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* ELEMENTS */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.video-bg:after,
.bg:after {
	content: '';
	display: block;
}

.video-bg, .video-bg:after, .wr-hd, .bg, .bg:after {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.bg {
	background-size: cover;
	background-repeat: no-repeat;
}

.wr-hd {
	overflow: hidden;
	z-index: -1;
}

.bg.parallax-bg {
	height: 125%;
	top: -10%;
}

.video-bg video {
	position: absolute;
	top: 50%;
	left: 50%;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%) scale(1.1);
}

.icon-arrow {
	position: relative;
	display: inline-block;
	width: 7px;
	height: 7px;
}

.icon-arrow::before, .icon-arrow::after {
	content: "";
	position: absolute;
	top: 0;
	width: 1px;
	height: 9px;
	background-color: #111111;
}

.icon-arrow::before {
	left: 0;
	transform: rotate(-45deg);
}

.icon-arrow::after {
	right: 0;
	transform: rotate(45deg);
}

#loader-wrapper {
	position: fixed;
	left: 0;
	top: -100px;
	right: 0;
	bottom: -100px;
	background: #ffffff;
	z-index: 2000;
}

.section {
	position: relative;
}

.separator {
	border-bottom: 1px solid #dcecf2;
}

.image-wrapper img {
	max-width: 100%;
}

.container-cs{
	max-width: 1760px;
	padding: 0 32px;
	margin: 0 auto;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* SEPARATOP MB, MT */
/*-------------------------------------------------------------------------------------------------------------------------------*/
.lb-mb-2{
	margin-bottom: 2px;
}
.lb-mb-4{
	margin-bottom: 4px;
}
.lb-mb-8{
	margin-bottom: 8px;
}
.lb-mb-15{
	margin-bottom: 15px;
}
.lb-mb-20{
	margin-bottom: 20px;
}
.lb-mb-32{
	margin-bottom: 32px;
}
.lb-mb-40{
	margin-bottom: 40px;
}
.lb-tp-5{
	padding-top: 5px;
}
.lb-tp-10{
	padding-top: 10px;
}
.lb-tp-15{
	padding-top: 15px;
}
.lb-tp-25{
	padding-top: 25px;
}
.lb-tp-30{
	padding-top: 30px;
}
.lb-bp-32{
	padding-bottom: 32px;
}
.lb-tp-40{
	padding-top: 40px;
}
.lb-mr-8{
	margin-right: 8px;
}
.lb-mr-26{
	margin-right: 26px;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* Setting grid */
/*-------------------------------------------------------------------------------------------------------------------------------*/

.row{
	&.row-50{
		> [class*=col]{
			padding: 0 25px;
		}
		margin: 0 -25px;
	}
	&.row-32{
		> [class*=col]{
			padding: 0 16px;
		}
		margin: 0 -16px;
	}
	&.row-24{
		> [class*=col]{
			padding: 0 12px;
		}
		margin: 0 -12px;
	}
	&.row-12{
		> [class*=col]{
			padding: 0 6px;
		}
		margin: 0 -6px;
	}
	&.row-8{
		> [class*=col]{
			padding: 0 4px;
		}
		margin: 0 -4px;
	}
	&.nowrap{
		flex-wrap: nowrap;
	}
	> .col-w-100{
		width: 100%;
	}
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* LOADER */
/*-------------------------------------------------------------------------------------------------------------------------------*/
@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(-360deg);
	}
}
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(-360deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(-360deg);
		transform:rotate(-360deg);
	}
}

.loader{
	&-wrapper{
		text-align: center;
		padding: 30px;
		&.p-0{
			padding: 0;
		}
	}
	&-circle{
		display: inline-block;
		line-height: 0;
		width: 46px;
		-webkit-animation:spin 1s linear infinite;
		-moz-animation:spin 1s linear infinite;
		animation:spin 1s linear infinite;
		img{
			width: 100%;
		}
		&.md{
			width: 24px;
		}
		&.sm{
			width: 16px;
		}
	}
}


/*-------------------------------------------------------------------------------------------------------------------------------*/
/* Line*/
/*-------------------------------------------------------------------------------------------------------------------------------*/

.line-hr-or{
	display: flex;
	justify-content: space-between;
	align-items: center;
	span{
		display: inline-block;
		padding: 0 15px;
	}
	hr{
		display: block;
		height: 1px;
		background-color: $colorGrey6-2;
		width: 100%;
	}
	&.sp-t-b{
		margin: 15px 0;
	}
}
.hr{
	display: block;
	height: 1px;
	background-color: $colorGrey6-2;
	width: 100%;
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* Max Line*/
/*-------------------------------------------------------------------------------------------------------------------------------*/
.max-l{
	&-a{
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}
	&-b{
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	&-c{
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
}

.hr{
	&-top{
		border-top: 1px solid $colorGrey5;
	}
	&-right{
		border-right: 1px solid $colorGrey5;
	}
	&-bottom{
		border-bottom: 1px solid $colorGrey5;
	}
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* Editor */
/*-------------------------------------------------------------------------------------------------------------------------------*/

.text-with-editor{
	line-height: 1.8em!important;
	a:not(.btn-outlined){
		color: #0F62FE;
		text-decoration: none;
		font-weight: 500;
	}
	a[target='btn-outlined'] {
		padding: 4px 12px;
		border-radius: 6px;
		background-color: rgba(1, 1, 1, 0);
		border: 1px solid #E6E6E6;
		color: #5E4BF1;
		transition: all 0.34s ease-out;
		font-weight: 500;
		margin-right: 8px;
		display: inline;
	}
	a[target='btn-outlined']:hover{
		background-color: rgba(94, 75, 241, 0.08);
	}
}

/*==========  Hover  ==========*/

// @media (min-width: 1200px){}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px){}

// @media (max-width: 1199px){}

// @media (max-width: 991px){}

// @media (max-width: 768px){}

// @media (max-width: 575px){}


