/*==========  Progress-bar Style  ==========*/
.progress{
  &-wrapper{
    display: block;
    width: 100%;
    height: 8px;
    position: relative;
    border-radius: 8px;
    background-color: $colorGrey6-2;
    &.type-2{
      height: 4px;
    }
  }
  &-inner{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 8px;
    background-color: $color2;
    &.orang{
      background-color: #FFAF3C;
    }
    &.red{
      background-color: #FA665B;
    }
  }
}

/*==========  Badge Style  ==========*/
.badge{
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 7px 8px;
  color: #AAAAAA;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  &.sm{
    font-size: 16px;
    padding: 2px 6px;
    font-weight: 400;
    border-radius: 6px;
  }
  &-wrapper{
    margin-bottom: -8px;
    > .badge{
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
  &.checkbox-item{
    position: relative;
    overflow: hidden;
    display: inline-flex;
    cursor: pointer;
    transition: $transition;
    z-index: 2;
    input{
      display: none;
    }
    > span{
      transition: $transition;
    }
    > span:after{
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #ece9fd;
      z-index: -1;
      top: 0;
      left: 0;
      visibility: hidden;
      opacity: 0;
    }
    input:checked + span{
      color: #5E4BF1;
    }
    input:checked + span:after{
      visibility: visible;
      opacity: 1;
    }
  }
  &.btn{
    cursor: pointer;
    transition: $transition;
  }
}
.badge-sm{
  padding: 2px 8px;
  font-size: 16px;
  border-radius: 16px;
  background-color: #E9E9E9;
}
.badge-orang{
  padding: 2px 5px;
  color: #DE5F04;
  background-color: rgba(222, 95, 4, 0.08);
  border-radius: 5px;
  font-size: 12px;
}
.badge-dark{
  padding: 2px 5px;
  background-color: #090822;
  color: #FFFFFF;
  border-radius: 5px;
  font-size: 12px;
  &-wrap{
    .badge-dark{
      margin-right: 4px;
    }
  }
}

.badge-green{
  display: inline-block;
  padding: 4px 6px;
  color: #fff;
  background-color: #02BB9F;
  border-radius: 6px;
  font-size: 12px;
}

.badge-ml{
  display: inline-block;
  padding: 4px 6px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.2em;
  &.bg-grey{
    background-color: $colorGrey6-2;
  }
  &.bg-grey2{
    background-color: $colorGrey2;
  }
  &.rd2{
    border-radius: 16px;
  }
  &.text-sm{
    font-size: 12px;
  }
  &.badge-icon{
    span{
      vertical-align: middle;
    }
  }
  .icon{
    display: inline-block;
    line-height: 0;
    margin-right: 4px;
    width: 17px;
    img{
      width: 100%;
    }
  }
  &-wrapper{
    margin-bottom: -4px;
    .badge-ml{
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }
  &.cirle{
    border-radius: 50%;
    min-width: 22px;
    text-align: center;
  }
  &.bg-red{
    color: #fff;
    background-color: #FA665B;
  }
  &.bg-green{
    color: #fff;
    background-color: #02BB9F;
  }
  &.bg-orang{
    color: #fff;
    background-color: #FFAF3C;
  }
  &.bg-gr1{
    background-color: $colorGrey1;
    color: $colorGrey10;
  }
  &.bg-gr2{
    background-color: $colorGrey6-2;
    color: #000000;
  }
  &.sm{
    font-size: 12px;
  }
}

/*==========  List Style  ==========*/
.list-style{
  li{
    margin-bottom: 8px;
    padding-left: 24px;
  }
  &.type-a{
    li:not(.not-included){
      position: relative;
      &:after{
        content: "";
        display: block;
        width: 6px;
        height: 10px;
        border-bottom: 2px solid $colorGrey7;
        border-right: 2px solid $colorGrey7;
        transform: rotate(45deg);
        position: absolute;
        top: 4px;
        left: 8px;
      }
    }
    li.not-included{
      position: relative;
      color: #AAAAAA;
      &:after{
        content: "✖";
        display: inline-block;
        position: absolute;
        border: none;
        transform: none;
        color: #AAAAAA;
        height: auto;
        width: auto;
        top: 0px;
        left: 5px;
      }
    }
  }
}

/*==========  Dropdown Style  ==========*/
.dropdown{
  position: relative;
  &:not(.no-arrow){
    .btn{
      padding-right: 32px;
      text-align: left;
      &:after{
        content: "";
        border: 4px solid transparent;
        border-top: 6px solid currentColor;
        position: absolute;
        top: 60%;
        transform-origin: 45% 30%;
        transform: translateY(-50%);
        right: 13px;
        transition: all .24s ease-out;
      }
    }
  }
  &-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 28;
    display: none;
  }
  &-inner{
    background-color: #fff;
    border-radius: 16px;
    padding: 14px;
    filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.12));
    position: absolute;
    z-index: 32;
    top: calc(100% + 10px);
    left: 0;
    min-width: 180px;
    max-width: 220px;
    transition: $transition;
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
    font-size: 14px;
    li{
      margin-bottom: 2px;
      border-radius: 8px;
      padding: 2px 10px;
      color: #161616;
      cursor: pointer;
      transition: all 0.24s ease-out;
      display: -webkit-box;
      overflow: hidden;
      line-height: 2.1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      &.is-active{
        background-color: rgba(94, 75, 241, 0.12)!important;
        color: $color1;
      }
    }
    .in_mx_scroll{
      max-height: 250px;
      overflow-y: auto;
      padding: 0 5px;
      margin: 0 -5px;
    }
  }
  &.checkbox{
    li{
      padding: 0;
    }
    label{
      padding: 7px 10px;
      display: block;
      span{
        line-height: 20px;
        padding-left: 22px;
        color: #161616;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
  &.is-active{
    .dropdown-overlay{
      display: block;
    }
    .dropdown-inner{
      visibility: visible;
      opacity: 1;
      transform: none;
    }
    .btn:after{
      transform: translateY(-50%) rotate(180deg);
    }
    .btn-outlined{
      background-color: $color5;
    }
    .btn-ghost{
      background-color: $color4;
    }
  }
  &.min-a{
    .btn{
      min-width: 135px;
    }
  }
  &.right{
    .dropdown-inner{
      left: auto;
      right: 0;
    }
  }
  &-wrapper{
    display: flex;
    align-items: center;
    .dropdown{
      margin-right: 14px;
      &:last-child{
        margin-right: 0;
      }
    }
    &.sm{
      .dropdown{
        margin-right: 4px;
        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
}

/*==========  Card Style  ==========*/
.card{
  padding: 8px;
  border-radius: 16px;
  background-color: rgba(1,1,1,0);
  transition: $transition;
  display: block;
  text-decoration: none;
  cursor: pointer;
  //height: 100%;
  &-image{
    border-radius: 8px;
    margin-bottom: 16px;
    width: 100%;
    padding-bottom: 60%;
    position: relative;
    overflow: hidden;
    img{
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &-rating{
    span{
      vertical-align: middle;
    }
    .icon{
      margin-right: 5px;
      display: inline-block;
      width: 20px;
      line-height: 0;
      position: relative;
      top: -1px;
      img{
        width: 100%;
      }
    }
  }
  &-wrap{
    margin-bottom: -24px;
    > [class*=col-]{
      margin-bottom: 24px;
    }
  }
  &-title{
    display: block;
  }
  &-info{
    display: flex;
    justify-content: space-between;
    .tags{
      margin-right: 15px;
    }
  }
  &.type-two{
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &.bg-white{
    background-color: #FFFFFF;
  }
  .badge-ml{
    max-width: 100%;
    display: inline-flex;
    align-items: center;
    .icon{
      flex-shrink: 0;
    }
    .link{
      word-wrap: break-word;
      display: block;
      max-width: calc(100% - 17px);
    }
  }
}

/*==========  Accordion Style  ==========*/
.accordion{
  &-item{
    .btn-accordion{
      display: inline-block;
      width: 16px;
      height: 16px;
      line-height: 0;
      margin-left: 5px;
      vertical-align: middle;
      transform: none;
      transition: $transition;
      img{
        width: 100%;
      }
    }
    &.is-active{
      .btn-accordion{
        transform: rotate(180deg);
      }
    }
  }
  &-inner{
    padding-left: 24px;
    //transform  : translateY(0);
    //transition : 150ms transform ease-in;
    //overflow: hidden;
  }
}

/*==========  Menu-list Style  ==========*/
.menu-list-ms{
  li{
    padding: 10px 12px 10px 48px;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    color: $color3;
    transition: $transition;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: rgba(1,1,1,0);
    cursor: pointer;
    .icon{
      width: 24px;
      height: 24px;
      line-height: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
      img{
        max-width: 100%;
      }
    }
    &.is-active{
      background-color: $colorGrey6-2;
    }
  }
  &.type-two{
    li{
      font-weight: 400;
      padding: 6px 12px 6px 40px;
      margin-bottom: 4px;
      .icon{
        left: 8px;
      }
      .svg-fill{
        fill: $colorGrey7;
      }
      &.is-active{
        background-color: $color4;
        color: $color1;
        .svg-fill{
          fill: $color1;
        }
      }
    }
  }
  &.mr-r-l{
    margin: 0 -10px;
  }
}

/*==========  Tooltips  ==========*/
.tooltips{
  position: absolute;
  background-color: #090822;
  border-radius: 4px;
  padding: 8px 14px;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 1.2em;
  top: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
  cursor: default;
  pointer-events: none;
  opacity: 0;
  transition: all .22s ease-out;
  width: max-content;
  z-index: 4;
  max-width: 300px;
  &:after{
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 3px;
    background-color: #090822;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  .in-text{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  span:not(.in-text){
    display: inline-block;
    margin-right: 15px;
    width: max-content;
    &:last-of-type{
      margin-right: 0;
    }
  }
  &-ms{
    position: relative;
  }
}

/*==========  Tooltips  ==========*/
.thumbnail-tickets{
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 12px 16px;
  border-radius: 8px;
  margin-bottom: 2px;
  &:last-child{
    margin-bottom: 0;
  }
  .user{
    display: flex;
    align-items: center;
    .image{
      width: 24px;
      flex: 0 0 24px;
      height: 24px;
      margin-right: 6px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name{
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .assign{
    display: flex;
    align-items: center;
    margin-right: 15px;
    .image{
      width: 24px;
      flex: 0 0 24px;
      height: 24px;
      margin-right: 6px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name{
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .theme{
    .text-in{
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .info-more{
    margin-left: auto;
  }
  &-head{
    display: flex;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 16px;
  }
}
.thumbnail-tickets,
.thumbnail-tickets-head{
  .check{
    flex: 0 0 25px;
    margin-right: 15px;
    line-height: 0;
  }
  .priority{
    width: 100%;
    max-width: 90px;
    margin-right: 15px;
  }
  .user{
    width: 100%;
    max-width: 24%;
    margin-right: 15px;
  }
  .theme{
    width: 100%;
    max-width: 24%;
    margin-right: 15px;
  }
  .status{
    width: 100%;
    max-width: 240px;
    margin-right: 15px;
  }
  .assign{
    width: 100%;
    max-width: 24%;
  }
}

/*==========  Hover  ==========*/

@media (min-width: 1200px){
  .autocomplete-inner li:hover{
    background-color: $colorGrey6;
  }
  .dropdown-inner li:hover{
    background-color: $colorGrey6-2;
  }
  a.card:hover{
    background-color: $colorGrey4;
  }
  .card:hover{
    background-color: $colorGrey4;
  }
  .badge.btn:hover{
    opacity: 0.85;
  }
  .users-list-item:hover{
    background-color: $color4;
  }
  .menu-list-ms li:hover{
    background-color: $colorGrey6-2;
  }
  .users-list-two li:hover{
    background-color: $colorGrey6-2;
  }
  .tooltips-ms:hover .tooltips{
    opacity: 1;
    top: calc(100% + 10px);
  }
  .atc-list-item:hover{
    background-color: $colorGrey6-2;
  }
}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1368px){}

// @media (max-width: 1199px){}

// @media (max-width: 991px){}

// @media (max-width: 768px){}

// @media (max-width: 575px){}




