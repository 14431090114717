/*==========  Aside-nav Style  ==========*/

.aside-nav{
  display: flex;
  background-color: #FFFFFF;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  width: 64px;
  padding: 15px 0 15px 0;
  z-index: 8;
  &_logo{
    width: 40px;
    text-align: center;
    line-height: 0;
    display: inline-block;
    margin-bottom: 25px;
    cursor: pointer;
    img{
      max-width: 100%;
    }
  }
  &_top{
    margin-bottom: auto;
    width: 100%;
    li{
      display: block;
      padding: 10px 4px;
      margin-bottom: 20px;
      line-height: 0;
      text-align: center;
      position: relative;
      cursor: pointer;
      transition: $transition;
      .icon{
        display: inline-block;
        width: 24px;
        line-height: 0;
        svg{
          width: 100%
        }
      }
      &:before{
        content: '';
        display: block;
        height: 100%;
        width: 4px;
        position: absolute;
        left: 0;
        top: 0;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        background-color: $color1;
        visibility: hidden;
        opacity: 0;
      }
      &.is-active{
        background-color: $colorGrey6;
        &:before{
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  &_bottom{
    .menu{
      li{
        margin-bottom: 25px;
        text-align: center;
        a{
          display: inline-block;
          text-decoration: none;
        }
        .iocn{
          display: inline-block;
          width: 24px;
          line-height: 0;
          svg{
            width: 100%;
          }
        }
      }
    }
    .image-user{
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
  &.br-right{
    border-right: 1px solid #E6E6E6;
  }
}

.svg-icon-hover{
  .fill-light-grey{
    fill: #EEEEEE;
  }
  .fill-dark-grey{
    fill: #ADADAD;
  }
  .fill-dark-grey,
  .fill-light-grey{
    transition: $transition;
  }
  &.is-active{
    .fill-light-grey{
      fill: #A2A1FF;
    }
    .fill-dark-grey{
      fill: #6563FF;
      &.light{
        fill: #fff;
      }
    }
  }
}

/*==========  Hover  ==========*/

@media (min-width: 1200px){
  .svg-icon-hover:hover{
    .fill-light-grey{
      fill: #A2A1FF;
    }
    .fill-dark-grey{
      fill: #6563FF;
      &.light{
        fill: #fff;
      }
    }
  }

  .aside-nav_top li:hover{
    background-color: $colorGrey6;
  }
}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px){}

// @media (max-width: 1199px){}

// @media (max-width: 991px){}

// @media (max-width: 768px){}

// @media (max-width: 575px){}




