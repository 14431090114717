/*==========  PopUp Style  ==========*/
.modal{
  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 21;
    background-color: rgba(0, 0, 0, 0.3);
  }
  &-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 22;
    width: 100%;
    max-width: 530px;
    background-color: #fff;
    border-radius: 16px;
    padding: 24px;
    .btn-close{
      position: absolute;
      top: 16px;
      right: 16px;
    }
    &.type-two{
      max-width: 368px;
    }
  }
}

.modal{
  &-container-two{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 22;
    width: 100%;
    height: 100%;
    max-width: 80%;
    background-color: $colorGrey8;
    padding: 32px;
    .btn-close{
      position: absolute;
      top: 24px;
      right: 32px;
    }
    &.type-two{
      background-color: #FFFFFF;
      max-width: 480px;
    }
    &.type-three{
      background-color: #FFFFFF;
      max-width: 672px;
      overflow-y: auto;
    }
  }
  &-container-height{
    height: 100%;
  }
  &-no-choose{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    .image{
      max-width: 125px;
      img{
        max-width: 100%;
      }
    }
  }
  &-container-block{
    margin-bottom: 16px;
    background-color: #FFFFFF;
    border-radius: 16px;
    padding: 24px;
  }
}

.modal-container-chat{
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  z-index: 22;
  width: 100%;
  height: 100%;
  max-width: 80%;
  max-height: 80%;
  background-color: #fff;
  border-radius: 8px;
  padding: 32px;
  .btn-close{
    position: absolute;
    top: 24px;
    right: 32px;
  }
  &.type-two{
    height: auto;
    max-width: 300px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.zoom-enter-active,
.zoom-leave-active {
  transition: transform 0.5s, opacity 0.5s;
}

.zoom-enter,
.zoom-leave-to {
  transform: translate(50%, -50%) scale(0.75);
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-50%) translateY(100vh);
}

.slideleft-enter-active,
.slideleft-leave-active {
  transition: transform 0.5s;
}
.slideleft-enter,
.slideleft-leave-to {
  transform: translateY(0) translateX(100vw);
}

/*==========  Hover  ==========*/

@media (min-width: 1200px){}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1368px){}

// @media (max-width: 1199px){}

// @media (max-width: 991px){}

// @media (max-width: 768px){}

// @media (max-width: 575px){}




