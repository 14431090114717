/*==========  Find-vacancies Style  ==========*/
.block-layout.block-layout-vacanc{
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0px;
  overflow: hidden;
}

.vacanc{
  &-search{
    display: flex;
    .input-wrapper-icon{
      flex: 1 1 50%;
      margin-right: 8px;
    }
    .btn{
      white-space: nowrap;
    }
  }
  &-wrapper{
    display: flex;
  }
  &-list{
    flex: 0 0 400px;
    border-right: 2px solid $colorGrey6;
    &-header{
      padding-right: 16px;
      padding-left: 16px;
      padding-top: 16px;
    }
  }
  &-detailed{
    padding: 16px;
    width: 100%;
    overflow-y: auto;
    &-header{
      margin-bottom: 24px;
    }
    .name{
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      &-rating{
        margin-left: 4px;
        font-size: 12px;
        color: #FFAF3C;
        span{
          display: inline-block;
          vertical-align: middle;
        }
      }
      .icon{
        display: inline-block;
        width: 16px;
        line-height: 0;
        margin-left: 2px;
        svg{
          width: 100%;
        }
      }
    }
    .vacanc{
      margin-bottom: 4px;
    }
    .city{
      .icon{
        width: 16px;
        margin-right: 2px;
        line-height: 0;
        img{
          width: 100%;
        }
      }
      span{
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
.vacanc-list-item{
  padding: 16px 50px 16px 16px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: $colorGrey6;
  position: relative;
  cursor: pointer;
  border-bottom: 2px solid $colorGrey6;
  transition: $transition;
  &.active{
    background-color: $color4!important;
    pointer-events: none;
    .vacanc,
    .city,
    .rating,
    .name{
      color: #5E4BF1;
    }
    .fill-orang,
    .fill-blue{
      fill: #5E4BF1;
    }
  }
  .image{
    width: 48px;
    flex: 0 0 48px;
    height: 48px;
    border: 1px solid $colorGrey6-2;
    background-color: #fff;
    position: relative;
    border-radius: 8px;
    img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 80%;
      max-height: 80%;
    }
  }
  .info{
    padding-left: 12px;
    flex: 0 0 calc(100% - 48px);
  }
  .like{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 18px;
    width: 24px;
    line-height: 0;
    svg{
      width: 100%;
    }
  }
  .rating{
    flex: 0 0 100%;
    margin-top: 4px;
    color: #FFAF3C;
    transition: $transition;
    span{
      display: inline-block;
      vertical-align: middle;
    }
    .icon{
      display: inline-block;
      margin-left: 2px;
      width: 16px;
      line-height: 0;
      position: relative;
      bottom: -1px;
      svg{
        width: 100%;
      }
    }
  }
  .name{
    margin-bottom: 2px;
    color: $colorGrey7;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    transition: $transition;
  }
  .vacanc{
    margin-bottom: 4px;
    color: $color3;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    transition: $transition;
  }
  .city{
    position: relative;
    transition: $transition;
    padding-left: 18px;
    .icon{
      width: 16px;
      line-height: 0;
      margin-right: 2px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      svg{
        width: 100%;
      }
    }
  }
  .city-text{
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .fill-orang{
    fill: #FFAF3C;
    transition: $transition;
  }
  .fill-blue{
    fill: #6F6F6F;
    transition: $transition;
  }
}
.vacanc-list-wrapper{
  overflow-y: auto;
}
.block-layout{
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
}
.find-ch{
  &-filter{
    max-width: 280px;
    .badge-ml{
      border-radius: 16px;
    }
    .checkbox-entry span{
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  &-item{
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    align-items: center;
  }
}
.accordion-item{
  &.type-three{
    .accordion-inner{
      padding-left: 0!important;
      padding-top: 10px!important;
    }
    .btn-accordion{
      margin-left: auto!important;
      flex: 0 0 20px;
    }
  }
}
.show-more{
  &-inner{
    display: none;
  }
  &.show{
    .btn{
      display: none;
    }
    .show-more-inner{
      display: block;
    }
  }
}

/*==========  Hover  ==========*/

@media (min-width: 1200px){
  .vacanc-list-item:hover{
    background-color: $colorGrey6;
    //.vacanc,
    //.city,
    //.rating,
    //.name{
    //  color: #5E4BF1;
    //}
    //.fill-orang,
    //.fill-blue{
    //  fill: #5E4BF1;
    //}
  }
}

/*==========  MEDIA QUERIES  ==========*/

//@media (max-width: 1500px){}

// @media (max-width: 1368px){}

// @media (max-width: 1199px){}

// @media (max-width: 991px){}

// @media (max-width: 768px){}

// @media (max-width: 575px){}




