
/*==========  TYPOGRAPHY  ==========*/

body {
	font-family: $mainFont;
	color: #111111;
}

b, strong {
	font-weight: 700;
}

a:hover {
	text-decoration: none;
}

ul, ol {
	list-style: none;
}

body, .text {
	font-size: 16px;
	font-weight: 400;
	line-height: 1.25em;
	color: #767676;
}

.text-norm{
	font-size: 16px;
}

.text-md {
	font-size: 14px;
	line-height: 1.4em;
}

.text-sm {
	font-size: 12px;
	line-height: 1.1em;
}

.text-lg {
	font-size: 18px;
	line-height: 1.2em;
}

.text-light {
	font-weight: 300;
}
.text-bold{
	font-weight: 700;
}
.text-media{
	font-weight: 500;
}


.text > * {
	margin-bottom: 30px;
}

.text > *:last-child {
	margin-bottom: 0;
}

.text img {
	max-width: 100%;
	height: auto;
	display: block;
}

.text ol {
	counter-reset: number;
}

.text ol li {
	padding-left: 0;
	margin-bottom: 15px;
	position: relative;
}

.text ol li:last-of-type {
	margin-bottom: 0;
}

.text ol li:before {
	counter-increment: number;
	content: "0" counter(number) ".";
	margin-right: 8px;
	font-weight: 700;
	font-size: 14px;
	color: #111111;
}

.text ul li {
	padding-left: 20px;
	margin-bottom: 2px;
	position: relative;
}

.text ul li:last-of-type {
	margin-bottom: 0;
}

.text ul li:before {
	content: "";
	display: inline-block;
	background-color: #111111;
	background-repeat: no-repeat;
	background-size: contain;
	width: 5px;
	height: 5px;
	border-radius: 50%;
	vertical-align: middle;
	position: absolute;
	left: 6px;
	top: 0.55em;
}

.text blockquote {
	color: #1f1f1f;
	font-size: 20px;
	padding: 5px 5px 5px 25px;
	border-left: 4px solid #00a2e0;
	font-weight: 600;
}

.text > iframe {
	width: 100%;
	height: 480px;
	max-height: calc(56.3vw - 30px);
}
.text{
	table{
		border-spacing: 0;
		text-align: left;
		width: 100%;
	}
	th, td{
		padding: 8px 0;
		border-bottom: 1px solid #E6E6E6;
	}
	tr:last-of-type{
		th, td{
			border-bottom: none;
		}
	}
}
table.table{
	border-spacing: 0;
	text-align: left;
	width: 100%;
	th, td{
		padding: 8px 0;
		border-bottom: 1px solid #E6E6E6;
	}
	&:not(.type-two){
		tr:last-of-type{
			th, td{
				border-bottom: none;
			}
		}
	}
	&.min-first-col{
		td:first-of-type{
			width: 200px;
			padding-right: 5px;
		}
		td:last-of-type{
			max-width: calc(100% - 200px);
		}
	}
}

.text b {
	font-weight: 700;
	color: #111111;
}

h1, .h1{
	font-weight: 700;
	color: #161616;
}
h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	font-weight: 500;
	color: #161616;
}

h1, .h1 {
	font-size: 32px;
	line-height: 1.25em;
}

h2, .h2 {
	font-size: 24px;
	line-height: 1.16em;
}

h3, .h3 {
	font-size: 18px;
	line-height: 1.3em;
}

h4, .h4 {
	font-size: 16px;
	line-height: 1.3em;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.color-primary {
	color: #1f1f1f;
}

.color-secondary {
	color: #4c4c4c;
}

.color-grey{
	color: #767676;
}
.color-grey-2{
	color: #AAAAAA;
}
.color-grey-3{
	color: #6F6F6F;
}
.color-dark{
	color: #161616;
}
.color-dark-2{
	color: #000000;
}
.color-green{
	color: #1BBD48;
}
.color-green2{
	color: #02BB9F;
}
.color-red{
	color: #FA665B;
}



/*==========  Hover  ==========*/

// @media (min-width: 1200px)


/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px)

// @media (max-width: 1199px)

// @media (max-width: 991px)

// @media (max-width: 768px)

// @media (max-width: 575px)
